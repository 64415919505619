<template>
  <PortalIcon
    v-if="isPortalIcon"
    :icon="portalIcon.name"
    v-bind="{ ...$attrs, ...portalIcon.props }"
  />
  <i v-else :class="name" v-bind="$attrs"></i>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    PortalIcon: () => import("@/components/icons/portal-icon.vue")
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      portalIcon: {
        name: null,
        props: {}
      }
    };
  },
  computed: {
    isPortalIcon() {
      return this.portalIcon.name != null;
    }
  },
  watch: {
    name: {
      immediate: true,
      handler(icon) {
        if (icon?.startsWith?.("portal-icon")) {
          const iconModRegex = /\[(\w+)\]/g;
          let iconName = icon.split(" ")[1];
          let iconModifiers = iconName.match(iconModRegex);
          if (iconModifiers) {
            this.portalIcon.props = iconModifiers.reduce((obj, mod) => {
              obj[mod.substring(1, mod.length - 1)] = true;
              return obj;
            }, {});
          }
          this.portalIcon.name = iconName.replace(iconModRegex, "");
        } else {
          this.portalIcon = {
            name: null,
            props: {}
          };
        }
      }
    }
  }
};
</script>

<style></style>
